import React from 'react';
import { Helmet } from 'react-helmet';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import Footer from './global-components/footer';
import Terms from './section-components/terms';

const TermsAndConditions = () => {
  return (
<>
<Helmet>
  <title>Termini e condizioni - TrucksOut</title>
  <meta name="description" content="Leggi i termini e le condizioni di utilizzo di TrucksOut, la piattaforma gestionale pensata per le aziende di autotrasporto." />
  <meta name="robots" content="noindex, follow" />
</Helmet>
    <div >
        <Navbar />
        <PageHeader headertitle="Termini e Condizioni" />
        <Terms />
        <Footer />
    </div>
  </>);
};

export default TermsAndConditions;