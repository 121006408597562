import React from 'react';

import { Helmet } from 'react-helmet';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import Video from './section-components/video';
import Subscribe from './section-components/subscribe';
import Footer from './global-components/footer';

const About = () => {
    return (
<>
<Helmet>
  <title>Chi siamo - TrucksOut</title>
  <meta name="description" content="Conosci la missione di TrucksOut: semplificare la vita delle aziende di autotrasporto con un gestionale moderno, efficace e flessibile." />
  <meta name="robots" content="index, follow" />
</Helmet>

<div>
        <Navbar />
        <PageHeader headertitle="Chi siamo" />
        <Video customclass="pd-top-120 bg-none" />
        <Subscribe />
        <Footer />
    </div>
</>
        );
}

export default About

