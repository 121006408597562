import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

import Subscribe from './section-components/subscribe';
import Footer from './global-components/footer';
import ContactForm from './section-components/contact-form';


const OffertaLanding = () => {
  

  useEffect(() => {
    window.scrollTo(0, 0);

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        }
      });
    });

    const elements = document.querySelectorAll('.fade-in-up');
    elements.forEach(el => observer.observe(el));

    return () => observer.disconnect();
  }, []);


  return (
 <>
    <Helmet>
      <title>TrucksOut - Il gestionale semplificato per aziende di autotrasporto</title>
      <meta name="description" content="Scopri TrucksOut, il gestionale pensato per piccole e medie imprese di autotrasporto. Pianifica ritiri, consegne e ferie in pochi clic. Provalo gratis!" />
      <meta name="robots" content="index, follow" />

      {/* Open Graph */}
      <meta property="og:title" content="TrucksOut - Il gestionale semplificato" />
      <meta property="og:description" content="Il gestionale per aziende di autotrasporto che semplifica ritiri, consegne e ferie." />
      <meta property="og:image" content="https://www.trucksout.app/assets/img/cover-offerta.png" />
      <meta property="og:url" content="https://www.trucksout.app/offerta" />
      <meta property="og:type" content="website" />

      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="TrucksOut - Il gestionale semplificato" />
      <meta name="twitter:description" content="Gestisci ritiri, consegne e ferie con semplicità. Provalo gratis!" />
      <meta name="twitter:image" content="https://www.trucksout.app/assets/img/cover-offerta.png" />
    </Helmet>



    <main>
    <div className="lp-topbar">
      <div className="container">
        <a href="/" className="lp-logo">
          <img src="/assets/img/logo.png" alt="TrucksOut logo" height="70" />
        </a>
      </div>
    </div>

      {/* FASCIA 1 - HERO */}
      <section className="hero-section">
        <div className="container offerta">
          <div className="row align-items-center">
            <div className="col-md-6 text">
              <h1>TrucksOut - Il gestionale pensato per i tuoi autisti</h1>
              <p className="lead mt-3">
                <strong>TrucksOut</strong> ti aiuta a pianificare ritiri, consegne e ferie in pochi clic.<br /> Meno errori, più tempo per il tuo lavoro.
              </p>
              <div className="cta-buttons">
                <a href="https://dashboard.trucksout.app/register" className="btn btn-primary">Prova gratuita</a>
                <a href="https://calendly.com/ronald-trucksout/30min" className="btn btn-outline">Prenota demo</a>
              </div>
            </div>
            <div className="col-md-6 video">
              <div className="video-wrapper">
                <iframe
                  width="100%"
                  height="315"
                  src="https://www.youtube.com/embed/S2ftsnHD98s?autoplay=1&mute=1&rel=0&start=12"
                  title="TrucksOut demo video"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* FASCIA 2 - 4 PUNTI DI FORZA */}
      <section className="features-section fade-in-up">
        <div className="container text-center">
          <div className="row">
            <div className="col-md-3">
              <div className="icon-circle icon-calendar"></div>
              <h5>Pianificazione facile</h5>
              <p>Ordini, ferie e turni in una dashboard unica.</p>
            </div>
            <div className="col-md-3">
              <div className="icon-circle icon-clock"></div>
              <h5>Tempo risparmiato</h5>
              <p>Evita doppioni, errori e fogli Excel.</p>
            </div>
            <div className="col-md-3">
              <div className="icon-circle icon-flex"></div>
              <h5>100% flessibile</h5>
              <p>Senza vincoli, cancelli quando vuoi.</p>
            </div>
            <div className="col-md-3">
              <div className="icon-circle icon-sync"></div>
              <h5>App autisti sincronizzata</h5>
              <p>Ogni modifica è subito aggiornata.</p>
            </div>
          </div>
        </div>
      </section>

      {/* FASCIA 3 - PREZZI */}
      <section className="pricing-section fade-in-up">
        <div className="container text-center">
          <p className="mb-4">Un gestionale semplice, accessibile e senza sorprese.</p>
          <div className="row pricing-boxes">
            <div className="col-md-6">
              <div className="price-box">
                <h4>Piano ESSENTIAL</h4>
                <p className="price">29€/mese</p>
                <p>Accesso completo, cancellazione gratuita, ideale per iniziare.</p>
                 <div className="cta-buttons">
                  <a href="https://dashboard.trucksout.app/register" className="btn btn-primary">Prova gratuita</a>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="price-box highlight">
                <h4>Offerta LIFETIME FOUNDERS</h4>
                <p className="price">299€/anno</p>
                <p>12 mesi completi + supporto prioritario per sempre. Offerta limitata.</p>
                 <div className="cta-buttons">
                  <a href="https://dashboard.trucksout.app/register" className="btn btn-primary">Prova gratuita</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* FASCIA 4 - COMPARAZIONE */}
      <section className="comparison-section fade-in-up">
        <div className="container">
          <h2 className="text-center mb-5"><span>TrucksOut</span> vs altri gestionali</h2>
          <table className="table comparison-table">
            <thead>
              <tr>
                <th>Funzionalità</th>
                <th>TrucksOut</th>
                <th>Competitor</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Pianificazione ordini + ferie</td>
                <td>✅</td>
                <td>❌</td>
              </tr>
              <tr>
                <td>App autisti sincronizzata</td>
                <td>✅</td>
                <td>❌</td>
              </tr>
              <tr>
                <td>Prezzo fisso mensile</td>
                <td>✅</td>
                <td>❌ / variabile</td>
              </tr>
              <tr>
                <td>Senza vincoli contrattuali</td>
                <td>✅</td>
                <td>❌</td>
              </tr>
            </tbody>
          </table>
        </div>

        <ContactForm />
        <Subscribe />
     
      </section>
       <Footer />
    </main>
   </>
  );
};

export default OffertaLanding;