import React from 'react';
import { Helmet } from 'react-helmet';

import Navbar from './global-components/navbar';
import Slider from './section-components/slider';
import Features from './section-components/features';
import PricingTable from './section-components/pricing-table';
import Video from './section-components/video';
import Screenshot from './section-components/screenshot';
import Subscribe from './section-components/subscribe';
import Footer from './global-components/footer';

const Home = () => {
    return (
  <>
    <Helmet>
      <title>TrucksOut - Il gestionale semplificato per aziende di autotrasporto</title>
      <meta name="description" content="Scopri TrucksOut, il gestionale su misura per piccole flotte. Pianifica ritiri, consegne e ferie con semplicità. Provalo gratis!" />
      <meta name="robots" content="index, follow" />
    </Helmet>
    <div>
      <Navbar />
      <Slider />
      <Video />
      <Features />
      <Screenshot />
      <PricingTable />
      <Subscribe />
      <Footer />
    </div>
  </>
);
}

export default Home

