import React from 'react';
import { Helmet } from 'react-helmet';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import ContactForm from './section-components/contact-form';
import Footer from './global-components/footer';

const Contact = () => {
    return (
<>
<Helmet>
  <title>Contatti - TrucksOut</title>
  <meta name="description" content="Hai bisogno di piu informaizoni? Scrivici e saremo felici di aiutarti." />
  <meta name="robots" content="index, follow" />
</Helmet>
<div>
        <Navbar />
        <PageHeader headertitle="Contattaci" />
        <ContactForm />
        <Footer />
    </div>
</>
        );
}

export default Contact

