import React from 'react';
import { Helmet } from 'react-helmet';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import Pricing from './section-components/pricing-table';
import Subscribe from './section-components/subscribe';
import Footer from './global-components/footer';

const PricingPage = () => {
    return (
<>
<Helmet>
  <title>Prezzi - TrucksOut</title>
  <meta name="description" content="Scegli il piano TrucksOut più adatto a te. Nessun vincolo, cancellazione gratuita, ideale per piccole flotte di autotrasporto." />
  <meta name="robots" content="index, follow" />
</Helmet>

<div>
        <Navbar />
        <PageHeader headertitle="Prezzi" />
        <Pricing  />
        <Subscribe />
        <Footer />
    </div>
</>
        );
}

export default PricingPage

