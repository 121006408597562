import React from 'react';
import { Helmet } from 'react-helmet';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import Services from './section-components/services';
import PricingTable from './section-components/pricing-table';
import Faq from './section-components/faq';
import Subscribe from './section-components/subscribe';
import Footer from './global-components/footer';

const Service = () => {
    return (
<>
<Helmet>
  <title>Servizi - TrucksOut</title>
  <meta name="description" content="Scopri i servizi di TrucksOut: pianificazione ritiri e consegne, gestione ferie e sincronizzazione autisti. Tutto in un’unica dashboard." />
  <meta name="robots" content="index, follow" />
</Helmet>

<div>
        <Navbar />
        <PageHeader headertitle="Servizi" />
        <Services customclass="pd-top-112" />
        <Faq customclass="pd-top-120 " />
        <Subscribe />
        <Footer />
    </div>

</>

        );
}

export default Service

